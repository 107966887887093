import React from 'react';
import Head from '../components/Head';
import A from '../components/ui/A';
import Nav from '../components/ui/Nav';

export default () => (
  <div>
    <Nav />
    <Head title="Home" />
    <div className="flex flex-col container items-center justify-center h-screen">
      <div className="bg-cover bg-center" style={{ width: 200, height: 50, backgroundImage: "url('/static/img/logo-full.png')" }} />
      <div>Coming soon.</div>
      <div className="p-20 text-center">
        <A href="/app">
          Open app →
          </A>
      </div>
    </div>
  </div>
);
